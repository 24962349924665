import { createTheme } from '@mui/material/styles';

const colors = {
  primary: '#1C2159',           // Azul oscuro para fondo y secciones
  secondary: '#5E61F4',         // Azul vibrante para botones y detalles
  background: '#0B0D2C',        // Fondo oscuro principal
  surface: '#1E2149',           // Fondo para superficies elevadas
  textPrimary: '#FFFFFF',       // Texto claro para contraste
  textSecondary: '#B0B3F5',     // Texto en tonos pasteles para detalles
  gradient: 'linear-gradient(135deg, #1C2159 30%, #5E61F4 90%)', // Degradado suave para fondos y botones
};

const shadows = {
  light: '0px 4px 8px rgba(0, 0, 0, 0.2)',    // Sombra ligera para elementos elevados
  hover: '0px 6px 12px rgba(0, 0, 0, 0.4)',   // Sombra adicional para elementos en hover
  intense: '0px 8px 20px rgba(0, 0, 0, 0.6)', // Sombra intensa para elementos destacados
};

const animations = {
  hoverScale: 'scale(1.05)',
  gradientShift: 'gradient-shift 5s ease infinite',
  fadeIn: 'fade-in 1s ease forwards',
  transitionSpeed: '0.3s',
};

const theme = createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    background: { default: colors.background, paper: colors.surface },
    text: { primary: colors.textPrimary, secondary: colors.textSecondary },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: { fontSize: '3rem', fontWeight: 700, color: colors.textPrimary },
    h2: { fontSize: '2.5rem', fontWeight: 600, color: colors.textPrimary },
    body1: { fontSize: '1.1rem', fontWeight: 400, color: colors.textPrimary },
    button: { fontWeight: 600, textTransform: 'none' },
  },
  shadows,
  transitions: { duration: { standard: 300 } },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundImage: colors.gradient,
          color: colors.textPrimary,
          borderRadius: '20px',
          padding: '10px 20px',
          boxShadow: shadows.light,
          transition: `transform ${animations.transitionSpeed}, box-shadow ${animations.transitionSpeed}`,
          '&:hover': {
            transform: animations.hoverScale,
            boxShadow: shadows.hover,
            backgroundImage: colors.gradient,
          },
        },
      },
    },
  },
});

export default theme;
export { colors, shadows, animations };
