import React from 'react';
import { Container, Box, Typography, Button, Paper, TextField, Modal } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../theme';
import FullWidthBanner from '../components/FullWidthBanner'; // Importa el componente FullWidthBanner

const Header = styled(Box)({
  background: `linear-gradient(45deg, ${colors.primary}, ${colors.secondary})`,
  color: 'white',
  textAlign: 'center',
  padding: '3rem 2rem',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
});

const Section = styled(Paper)({
  padding: '2rem',
  margin: '2rem 0',
  borderRadius: '15px',
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
});

const CTAButton = styled(Button)({
  backgroundColor: colors.secondary,
  color: 'white',
  padding: '0.8rem 2.5rem',
  borderRadius: '30px',
  fontWeight: 600,
  boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
  fontSize: '1.1rem',
  '&:hover': {
    backgroundColor: colors.primary,
    transform: 'translateY(-3px)',
    boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
  },
});

const sessions = [
  {
    icon: 'handshake',
    title: 'Sesión 1: Diagnóstico Inicial',
    description: 'Exploramos tus procesos actuales y áreas de oportunidad.',
  },
  {
    icon: 'chart-line',
    title: 'Sesión 2: Análisis y Estrategia',
    description: 'Creamos una estrategia personalizada de IA para tu negocio.',
  },
  {
    icon: 'rocket',
    title: 'Sesión 3: Plan de Acción',
    description: 'Implementamos soluciones prácticas para optimizar tareas.',
  },
];

export default function Mentorías() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container maxWidth="md">
      <Header>
        <Typography variant="h3" style={{ fontWeight: 700, color: '#FFD700', textShadow: '2px 2px 4px rgba(0,0,0,0.4)' }}>
          Mentoría 1a1 EfficientAI
        </Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 400, fontSize: '1.2rem', marginTop: '0.5rem' }}>
          Impulsando tu negocio con el poder de la IA
        </Typography>
      </Header>

      <Section>
        <Typography variant="h4" color="primary" gutterBottom style={{ fontWeight: 600 }}>
          ¿A quién va dirigido?
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 300, marginBottom: '1rem' }}>
          Nuestra mentoría está especialmente diseñada para pequeñas empresas, autónomos y emprendedores que buscan optimizar procesos con IA.
        </Typography>
        <ul>
          <li><Typography variant="body2" style={{ fontWeight: 500 }}>Mejorar la eficiencia y productividad</Typography></li>
          <li><Typography variant="body2" style={{ fontWeight: 500 }}>Automatizar tareas repetitivas</Typography></li>
          <li><Typography variant="body2" style={{ fontWeight: 500 }}>Optimizar costos operativos</Typography></li>
        </ul>
      </Section>

      {/* Primer Descanso Virtual */}
      <FullWidthBanner text="La inteligencia artificial es el futuro, y el futuro es ahora. – Elon Musk" />

      <Section>
        <Typography variant="h4" color="primary" gutterBottom style={{ fontWeight: 600 }}>
          ¿Qué ofrecemos?
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 300 }}>
          Te brindamos una experiencia personalizada en 3 sesiones de 45 minutos cada una, identificando oportunidades para implementar IA.
        </Typography>
      </Section>

      <Section>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          {sessions.map((session, index) => (
            <Box key={index} sx={{ flex: '1 1 30%', margin: '1rem' }}>
              <Typography variant="h5" color="secondary" style={{ fontWeight: 600 }}>
                <i className={`fas fa-${session.icon}`} style={{ marginRight: '10px', color: colors.secondary }}></i> 
                {session.title}
              </Typography>
              <Typography variant="body2" style={{ color: colors.textSecondary, fontWeight: 400 }}>
                {session.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Section>

      {/* Segundo Descanso Virtual */}
      <FullWidthBanner text="Impulsa tu potencial, delega en la IA las tareas repetitivas y enfócate en crecer. – EfficientAI" />

      <Section style={{ background: colors.gradient, color: 'white' }}>
        <Typography variant="h4" color="inherit" gutterBottom style={{ fontWeight: 700 }}>
          Invierte en el futuro de tu negocio
        </Typography>
        <Typography variant="h5" color="inherit" style={{ fontWeight: 600 }}>
          Tan solo 99€
        </Typography>
        <Typography variant="body2" style={{ fontWeight: 400 }}>Pago único, incluye 3 sesiones personalizadas.</Typography>
        <Box textAlign="center" mt={3}>
          <CTAButton onClick={handleOpen}>¡Reserva tu mentoría ahora!</CTAButton>
        </Box>
      </Section>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '15px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" color="primary" gutterBottom style={{ fontWeight: 600 }}>Registro para Mentoría</Typography>
          <TextField fullWidth label="Nombre completo" margin="normal" variant="outlined" />
          <TextField fullWidth label="Email" margin="normal" variant="outlined" />
          <TextField fullWidth label="Teléfono" margin="normal" variant="outlined" />
          <Box mt={2}>
            <CTAButton fullWidth onClick={() => window.location.href = 'https://checkout.revolut.com/pay/19747ebe-3eb2-4966-ba24-71f836810bf3'}>
              Continuar al pago
            </CTAButton>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}
