// src/components/NewsletterSection.js
import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { colors } from '../theme'; // Agregar esta línea

export default function NewsletterSection() {
  return (
    <Box padding="40px 0">
      <Typography variant="h5" gutterBottom>Suscríbete al Newsletter</Typography>
      <Typography variant="body1">
        Mantente informado sobre las últimas tendencias en inteligencia artificial. Suscríbete y recibe noticias, consejos y ofertas exclusivas.
      </Typography>
      <Box mt={2}>
        <TextField
          variant="outlined"
          placeholder="Tu correo electrónico"
          fullWidth
          sx={{ backgroundColor: colors.surface, borderRadius: '5px', marginBottom: '10px' }}
        />
        <Button variant="contained" color="secondary" fullWidth>Suscribirse</Button>
      </Box>
    </Box>
  );
}
