import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';
import Slideshow from '../components/Slideshow'; // Importa el componente Slideshow
import SearchIcon from '@mui/icons-material/Search';
import BuildIcon from '@mui/icons-material/Build';
import SupportIcon from '@mui/icons-material/Support';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import PersonIcon from '@mui/icons-material/Person';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Section = styled(Box)({
  padding: '40px 0',
  textAlign: 'center',
});

const Title = styled(Typography)({
  marginBottom: '20px',
  fontWeight: 700,
});

const SubTitle = styled(Typography)({
  marginBottom: '10px',
  fontWeight: 600,
});

const CardItem = styled(Card)({
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  textAlign: 'center',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
});

const IconContainer = styled(Box)({
  fontSize: '3rem',
  color: '#1E88E5', // Color personalizable
  marginBottom: '10px',
});

// Datos del Slideshow
const images = [
  '/assets/images/image1.jpg', // Cambia estos paths por tus imágenes
  '/assets/images/chatbot2.jpg',
  '/assets/images/chatbot3.jpg',
];

const texts = [
  { line1: 'Chatbots Inteligentes', line2: 'Automatiza tu atención al cliente' },
  { line1: 'Desarrollo de WhatsApp Bots', line2: 'Especialistas en WhatsApp' },
  { line1: 'Soporte Continuo', line2: 'Aseguramos una experiencia de usuario óptima' },
];

export default function ChatBots() {
  return (
    <Container>
      {/* Slideshow en la parte superior */}
      <Slideshow images={images} texts={texts} />

      {/* Título de la Página */}
      <Section>
        <Title variant="h4">Chatbots Inteligentes: Mejora la atención al cliente con IA</Title>
        <Typography variant="body1" paragraph>
          En EfficientAI, somos especialistas en la creación e implementación de chatbots inteligentes que mejoran la atención al cliente y optimizan los procesos de comunicación.
          Con más de 3 años de experiencia en el desarrollo de WhatsApp bots, hemos ayudado a empresas y autónomos a automatizar su atención al cliente, reduciendo tiempos de
          respuesta y mejorando la satisfacción de sus usuarios.
        </Typography>
      </Section>

      {/* Sección: ¿Cómo trabajamos con chatbots? */}
      <Section>
        <SubTitle variant="h5">¿Cómo trabajamos con chatbots?</SubTitle>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <CardItem>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Typography variant="h6">Análisis de Necesidades</Typography>
              <Typography variant="body2">
                Estudiamos tu modelo de negocio y las interacciones más comunes con tus clientes para diseñar un chatbot que responda
                eficientemente a sus preguntas y necesidades.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardItem>
              <IconContainer>
                <BuildIcon />
              </IconContainer>
              <Typography variant="h6">Desarrollo de Chatbots Personalizados</Typography>
              <Typography variant="body2">
                Creamos chatbots adaptados a tu marca, sector y público, que se integran de forma fluida en tus sistemas actuales.
                Nuestros bots pueden implementarse en sitios web, redes sociales y especialmente en WhatsApp, nuestra especialidad.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardItem>
              <IconContainer>
                <SupportIcon />
              </IconContainer>
              <Typography variant="h6">Soporte y Optimización Continua</Typography>
              <Typography variant="body2">
                No nos detenemos en la implementación. Monitorizamos el rendimiento de los chatbots y los optimizamos continuamente
                para asegurar que siempre ofrezcan una experiencia de usuario excepcional.
              </Typography>
            </CardItem>
          </Grid>
        </Grid>
      </Section>

      {/* Sección: Beneficios de nuestros Chatbots */}
      <Section>
        <SubTitle variant="h5">Beneficios de nuestros Chatbots y WhatsApp Bots</SubTitle>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={3}>
            <CardItem>
              <IconContainer>
                <AccessTimeIcon />
              </IconContainer>
              <Typography variant="h6">Disponibilidad 24/7</Typography>
              <Typography variant="body2">
                Ofrece atención al cliente en todo momento, sin interrupciones.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem>
              <IconContainer>
                <AutoAwesomeMotionIcon />
              </IconContainer>
              <Typography variant="h6">Automatización de Procesos</Typography>
              <Typography variant="body2">
                Responde preguntas frecuentes, toma pedidos, gestiona citas y más, sin necesidad de intervención humana.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem>
              <IconContainer>
                <PersonIcon />
              </IconContainer>
              <Typography variant="h6">Personalización Avanzada</Typography>
              <Typography variant="body2">
                Los chatbots están diseñados para adaptarse a la voz y estilo de tu marca, proporcionando respuestas personalizadas.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem>
              <IconContainer>
                <WhatsAppIcon />
              </IconContainer>
              <Typography variant="h6">Especialización en WhatsApp Bots</Typography>
              <Typography variant="body2">
                Con más de 3 años de experiencia en el desarrollo de bots para WhatsApp, te ayudamos a automatizar una de las
                plataformas más utilizadas por tus clientes.
              </Typography>
            </CardItem>
          </Grid>
        </Grid>
      </Section>

      {/* Sección: Llamado a la acción */}
      <Section>
        <Typography variant="h5" gutterBottom>¿Quieres mejorar tu atención al cliente con chatbots?</Typography>
        <Typography variant="body1" paragraph>
          Ponte en contacto con nosotros para descubrir cómo un chatbot o WhatsApp bot puede ayudar a tu empresa a ahorrar tiempo
          y recursos, mientras mejora la experiencia de tus usuarios.
        </Typography>
        <Button variant="contained" color="primary" startIcon={<MailOutlineIcon />}>
          Contáctanos
        </Button>
      </Section>

      {/* Sección: Suscripción al Newsletter */}
      <Section>
        <SubTitle variant="h5">Suscríbete a nuestro Newsletter</SubTitle>
        <Typography variant="body1">
          Mantente informado sobre las últimas tendencias en IA, chatbots y automatización.
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <Box component="form" display="flex" alignItems="center">
            <input
              type="email"
              placeholder="Tu correo electrónico"
              style={{
                padding: '10px',
                borderRadius: '4px 0 0 4px',
                border: '1px solid #ccc',
                outline: 'none',
              }}
            />
            <Button variant="contained" color="secondary" style={{ borderRadius: '0 4px 4px 0' }}>
              Suscribirse
            </Button>
          </Box>
        </Box>
      </Section>
    </Container>
  );
}
