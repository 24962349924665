import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Link, Collapse, IconButton, Container } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { colors } from '../theme';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: colors.background,
  color: colors.textPrimary,
  padding: '10px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '20vh',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    paddingBottom: '15px',
  },
}));

const SocialIcons = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
  paddingBottom: '10px',
  '& a': {
    color: colors.textPrimary,
    fontSize: '1.2rem',
    transition: 'color 0.3s',
    '&:hover': {
      color: colors.secondary,
    },
  },
});

const SectionTitle = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  cursor: 'pointer',
  color: colors.textPrimary,
  justifyContent: 'center',
});

const NewsletterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '6px',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}));

export default function Footer() {
  const [expandedSections, setExpandedSections] = useState({
    navigation: false,
    contact: false,
    legal: false,
  });

  const handleExpand = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <FooterContainer>
      {/* Redes Sociales y Newsletter en la Parte Superior */}
      <SocialIcons>
        <Link href="#" aria-label="Facebook">
          <FacebookIcon />
        </Link>
        <Link href="#" aria-label="Twitter">
          <TwitterIcon />
        </Link>
        <Link href="#" aria-label="LinkedIn">
          <LinkedInIcon />
        </Link>
        <Link href="#" aria-label="Instagram">
          <InstagramIcon />
        </Link>
      </SocialIcons>

      <NewsletterContainer>
        <TextField
          variant="outlined"
          placeholder="Tu email"
          size="small"
          sx={{
            backgroundColor: colors.surface,
            borderRadius: '5px',
            width: '100%',
            '& .MuiInputBase-input': { fontSize: '0.75rem', padding: '4px 8px' },
          }}
          InputProps={{
            style: { height: '28px' },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            backgroundColor: colors.primary,
            fontSize: '0.7rem',
            padding: '4px 12px',
          }}
        >
          Suscribirse
        </Button>
      </NewsletterContainer>

      {/* Secciones Expandibles para Móvil */}
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            justifyContent: { sm: 'space-around' },
            width: '100%',
          }}
        >
          <Box sx={{ textAlign: 'center', flex: 1 }}>
            <SectionTitle onClick={() => handleExpand('navigation')}>
              Navegación
              <IconButton size="small" color="inherit">
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            </SectionTitle>
            <Collapse in={expandedSections.navigation} timeout="auto" unmountOnExit>
              <Box mt={1}>
                <Link href="/" color="inherit" underline="hover" style={{ fontSize: '0.75rem' }}>Inicio</Link> |
                <Link href="/consultoria" color="inherit" underline="hover" style={{ fontSize: '0.75rem' }}> Consultoría</Link> |
                <Link href="/mentorias" color="inherit" underline="hover" style={{ fontSize: '0.75rem' }}> Mentorías</Link>
              </Box>
            </Collapse>
          </Box>

          <Box sx={{ textAlign: 'center', flex: 1 }}>
            <SectionTitle onClick={() => handleExpand('contact')}>
              Contacto
              <IconButton size="small" color="inherit">
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            </SectionTitle>
            <Collapse in={expandedSections.contact} timeout="auto" unmountOnExit>
              <Box mt={1}>
                <Typography variant="body2" style={{ fontSize: '0.75rem', marginBottom: '2px' }}>info@efficientai.es</Typography>
                <Typography variant="body2" style={{ fontSize: '0.75rem' }}>+34 123 456 789</Typography>
              </Box>
            </Collapse>
          </Box>

          <Box sx={{ textAlign: 'center', flex: 1 }}>
            <SectionTitle onClick={() => handleExpand('legal')}>
              Legal
              <IconButton size="small" color="inherit">
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            </SectionTitle>
            <Collapse in={expandedSections.legal} timeout="auto" unmountOnExit>
              <Box mt={1}>
                <Link href="/politica-privacidad" color="inherit" underline="hover" style={{ fontSize: '0.75rem' }}>Privacidad</Link> |
                <Link href="/terminos-condiciones" color="inherit" underline="hover" style={{ fontSize: '0.75rem' }}> Términos</Link>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Container>

      {/* Derechos Reservados */}
      <Box textAlign="center" mt={1} style={{ fontSize: '0.6rem', borderTop: `1px solid ${colors.surface}`, paddingTop: '5px' }}>
        <Typography variant="body2">© 2023 EfficientAI. Todos los derechos reservados.</Typography>
      </Box>
    </FooterContainer>
  );
}
