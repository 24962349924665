import React from 'react';
import { Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IntroductionSection from '../components/IntroductionSection';
import ServicesSection from '../components/ServicesSection';
import BlogSection from '../components/BlogSection';
import ContactForm from '../components/ContactForm';
import NewsletterSection from '../components/NewsletterSection';
import Slideshow from '../components/Slideshow';

import image1 from '../assets/images/image1.jpg';
import image2 from '../assets/images/image2.jpg';
import image3 from '../assets/images/image3.jpg';

export default function Home() {
  const navigate = useNavigate();

  // Función para redirigir a la página "Estamos trabajando"
  const redirectToUnderConstruction = () => {
    navigate('/under-construction');
  };

  const images = [image1, image2, image3];
  const texts = [
    { line1: "Bienvenido a nuestro sitio", line2: "Descubre lo que ofrecemos" },
    { line1: "Soluciones avanzadas", line2: "Impulsa tu negocio con IA" },
    { line1: "Explora nuestros servicios", line2: "y visita nuestro blog" }
  ];

  return (
    <Box>
      <Container>
        <Slideshow images={images} texts={texts} autoPlay={true} interval={3000} />
        <IntroductionSection onRedirect={redirectToUnderConstruction} />
        <ServicesSection onRedirect={redirectToUnderConstruction} />
        <BlogSection onRedirect={redirectToUnderConstruction} />
        <ContactForm />
        <NewsletterSection />
      </Container>
    </Box>
  );
}
