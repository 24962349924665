import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import theme from './theme';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Blog from './pages/Blog';
import Login from './pages/Login';
import Consultoria from './pages/Consultoria';
import Formacion from './pages/Formacion';
import ChatBots from './pages/ChatBots';
import UnderConstruction from './pages/UnderConstruction';
import Mentorias from './pages/Mentorias'; // Importa el componente Mentorias

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <div className="app-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/login" element={<Login />} />
            <Route path="/consultoria" element={<Consultoria />} />
            <Route path="/formacion" element={<Formacion />} />
            <Route path="/chatbots" element={<ChatBots />} />
            <Route path="/under-construction" element={<UnderConstruction />} />
            <Route path="/mentorias" element={<Mentorias />} /> {/* Nueva ruta para Mentorias */}
          </Routes>
        </div>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
