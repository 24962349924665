// src/components/ContactForm.js
import React from 'react';
import { Box, Typography, TextField, Checkbox, FormControlLabel, Button } from '@mui/material';
import { styled } from '@mui/system';

const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '20px',
});

export default function ContactForm() {
  return (
    <Box padding="40px 0">
      <Typography variant="h5" gutterBottom>Contáctanos</Typography>
      <Typography variant="body1">
        ¿Tienes alguna pregunta o quieres saber cómo nuestras soluciones pueden ayudarte? Contáctanos y hablemos sobre cómo podemos transformar tu negocio.
      </Typography>
      <FormContainer>
        <TextField label="Nombre" variant="outlined" fullWidth required />
        <TextField label="Email" variant="outlined" fullWidth required />
        <TextField label="Mensaje" variant="outlined" fullWidth multiline rows={4} required />
        <FormControlLabel control={<Checkbox color="secondary" required />} label="Acepto la política de privacidad" />
        <FormControlLabel control={<Checkbox color="secondary" />} label="Deseo suscribirme a la newsletter" />
        <Button variant="contained" color="secondary" fullWidth>Enviar</Button>
      </FormContainer>
    </Box>
  );
}
