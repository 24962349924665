import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';

const Section = styled(Box)({
  padding: '40px 0',
});

const Title = styled(Typography)({
  marginBottom: '20px',
  fontWeight: 700,
  color: '#FFFFFF',
});

const BlogCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  marginBottom: '20px',
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.1)', // Fondo semitransparente para efecto de vidrio
  boxShadow: '0 8px 32px rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(10px)', // Desenfoque para simular vidrio esmerilado
  border: '1px solid rgba(255, 255, 255, 0.18)', // Borde transparente
  width: '100%',
  maxWidth: '400px',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)', // Efecto de zoom al pasar el ratón
    boxShadow: '0 12px 40px rgba(31, 38, 135, 0.4)',
  },
});

const BlogImage = styled('img')({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
  borderRadius: '15px',
  marginBottom: '15px',
});

const BlogContent = styled(CardContent)({
  textAlign: 'center',
  color: '#FFFFFF',
});

const ButtonStyled = styled(Button)({
  marginTop: '10px',
  color: '#FFFFFF',
  borderColor: 'rgba(255, 255, 255, 0.5)',
  '&:hover': {
    borderColor: '#FFFFFF',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
});

export default function Blog() {
  const [blogPosts, setBlogPosts] = useState([]);

  // Cargar el archivo JSON al montar el componente
  useEffect(() => {
    fetch('/assets/blogPosts.json')
      .then((response) => response.json())
      .then((data) => setBlogPosts(data))
      .catch((error) => console.error('Error loading blog posts:', error));
  }, []);

  return (
    <Container>
      {/* Título de la Página */}
      <Section>
        <Title variant="h4">Blog: Diario de una Vida Real</Title>
        <Typography variant="h5" gutterBottom style={{ color: '#b0b0b0' }}>
          Últimas Publicaciones
        </Typography>
        <Typography variant="body1" color="textSecondary" style={{ color: '#b0b0b0' }}>
          Explora nuestros artículos sobre inteligencia artificial y automatización.
        </Typography>
      </Section>

      {/* Listado de Entradas del Blog */}
      <Section display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
        {blogPosts.map((post, index) => (
          <BlogCard key={index}>
            <BlogImage src={post.image} alt={post.title} />
            <BlogContent>
              <Typography variant="h6" style={{ fontWeight: 700 }}>{post.title}</Typography>
              <Typography variant="body2" paragraph style={{ color: '#e0e0e0' }}>{post.description}</Typography>
              <ButtonStyled variant="outlined">Leer más</ButtonStyled>
            </BlogContent>
          </BlogCard>
        ))}
      </Section>
    </Container>
  );
}
