// src/components/ServicesSection.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material'; // Agregar Box a las importaciones
import ServiceCard from './ServiceCard';
import InsightsIcon from '@mui/icons-material/Insights';
import PersonIcon from '@mui/icons-material/Person';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School';
import ChatIcon from '@mui/icons-material/Chat';

export default function ServicesSection() {
  const services = [
    { title: 'Consultoría en IA', description: 'Analizamos tus procesos...', icon: InsightsIcon, link: '/consultoria' },
    { title: 'Mentorías 1 a 1', description: 'Te guiamos a través de...', icon: PersonIcon, link: '/mentorias' },
    { title: 'Desarrollo de Aplicaciones IA', description: 'Creamos soluciones...', icon: CodeIcon, link: '/aplicaciones' },
    { title: 'Formación y Capacitación', description: 'Capacita a tu equipo...', icon: SchoolIcon, link: '/formacion' },
    { title: 'Chatbots Inteligentes', description: 'Mejora tu atención al cliente...', icon: ChatIcon, link: '/chatbots' },
  ];

  return (
    <Box padding="40px 0">
      <Typography variant="h5" gutterBottom>Nuestras Soluciones y Servicios</Typography>
      <Grid container spacing={3}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ServiceCard {...service} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
