import React from 'react';
import { Box, Container, Typography, TextField, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const MapContainer = styled(Box)({
  height: '400px',
  width: '100%',
  marginBottom: '20px',
});

const InfoContainer = styled(Box)({
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
});

const ContactForm = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '20px',
});

export default function Contact() {
  const location = { lat: 37.236, lng: -3.6126 }; // Coordenadas para Peligros, Granada

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Contacto</Typography>
      <Typography variant="body1" paragraph>¿Tienes alguna pregunta? ¡Estamos aquí para ayudarte!</Typography>

      {/* Mapa Interactivo */}
      <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={location}
          zoom={15}
        >
          <Marker position={location} />
        </GoogleMap>
      </LoadScript>

      {/* Información de Contacto */}
      <InfoContainer mt={4} mb={4}>
        <Typography variant="h5" gutterBottom>Información de Contacto</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center">
              <PhoneIcon color="primary" />
              <Typography variant="body1" ml={1}>+34 693 04 66 31</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center">
              <MailOutlineIcon color="primary" />
              <Typography variant="body1" ml={1}>info@efficientai.es</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center">
              <LocationOnIcon color="primary" />
              <Typography variant="body1" ml={1}>Calle Estrella 2, 2ºB, 18210 Peligros (Granada), España</Typography>
            </Box>
          </Grid>
        </Grid>
      </InfoContainer>

      {/* Formulario de Contacto */}
      <Typography variant="h5" gutterBottom>Formulario de Contacto</Typography>
      <ContactForm component="form">
        <TextField label="Nombre" variant="outlined" fullWidth required />
        <TextField label="Email" variant="outlined" fullWidth required />
        <TextField label="Mensaje" variant="outlined" fullWidth multiline rows={4} required />
        <Button variant="contained" color="primary" fullWidth>Enviar</Button>
      </ContactForm>
    </Container>
  );
}
