import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import ServiceCard from '../components/ServiceCard';
import Slideshow from '../components/Slideshow'; // Importa el slideshow
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import BuildIcon from '@mui/icons-material/Build';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import UpdateIcon from '@mui/icons-material/Update';

// Importa las imágenes para el slideshow
import slide1 from '../assets/images/consultoria1.jpg';
import slide2 from '../assets/images/consultoria2.jpg';
import slide3 from '../assets/images/consultoria3.jpg';

export default function Consultoria() {
  // Define las imágenes y textos del slideshow
  const images = [slide1, slide2, slide3];
  const texts = [
    { line1: "Consultoría en IA", line2: "Transforma tu negocio" },
    { line1: "Optimización de Procesos", line2: "Aumenta la eficiencia con IA" },
    { line1: "Soluciones Personalizadas", line2: "Diseñadas para tu empresa" }
  ];

  return (
    <Container>
      {/* Slideshow */}
      <Slideshow images={images} texts={texts} autoPlay={true} interval={3000} />

      {/* Título y Descripción */}
      <Typography variant="h4" gutterBottom>
        Consultoría en IA: Lleva tu negocio al siguiente nivel
      </Typography>
      <Typography variant="body1" paragraph>
        En EfficientAI, te ayudamos a identificar cómo la inteligencia artificial puede transformar y optimizar tu empresa...
      </Typography>

      {/* Proceso de Consultoría */}
      <Typography variant="h5" gutterBottom>¿Cómo funciona nuestra consultoría?</Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <ServiceCard 
            icon={AssessmentIcon}
            title="Análisis de Necesidades"
            description="Realizamos un diagnóstico detallado de tus procesos actuales..."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ServiceCard 
            icon={TrackChangesIcon}
            title="Estrategias Personalizadas"
            description="Creamos un plan estratégico basado en las mejores prácticas de IA..."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ServiceCard 
            icon={BuildIcon}
            title="Implementación y Seguimiento"
            description="Te acompañamos en todo el proceso de implementación..."
          />
        </Grid>
      </Grid>

      {/* Beneficios de nuestra consultoría */}
      <Typography variant="h5" gutterBottom>Beneficios de nuestra consultoría</Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={3}>
          <ServiceCard 
            icon={PersonAddAltIcon}
            title="Soluciones a Medida"
            description="Diseñadas específicamente para las particularidades de tu empresa..."
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ServiceCard 
            icon={AccessTimeIcon}
            title="Mejora de la Eficiencia"
            description="Reducción de tiempos y optimización de recursos mediante..."
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ServiceCard 
            icon={ContactMailIcon}
            title="Acompañamiento Completo"
            description="Desde el análisis inicial hasta la implementación..."
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ServiceCard 
            icon={UpdateIcon}
            title="Actualizaciones Constantes"
            description="Nos aseguramos de que las soluciones implementadas..."
          />
        </Grid>
      </Grid>
    </Container>
  );
}
