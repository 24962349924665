import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';
import Slideshow from '../components/Slideshow'; // Importa el componente Slideshow
import SchoolIcon from '@mui/icons-material/School';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Section = styled(Box)({
  padding: '40px 0',
  textAlign: 'center',
});

const Title = styled(Typography)({
  marginBottom: '20px',
  fontWeight: 700,
});

const SubTitle = styled(Typography)({
  marginBottom: '10px',
  fontWeight: 600,
});

const CardItem = styled(Card)({
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  textAlign: 'center',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
});

const IconContainer = styled(Box)({
  fontSize: '3rem',
  color: '#1E88E5', // Puedes personalizar el color para que coincida con la paleta de colores del sitio
  marginBottom: '10px',
});

// Datos del Slideshow
const images = [
  '/assets/images/formacion1.jpg', // Cambia estos paths por tus imágenes
  '/assets/images/formacion2.jpg',
  '/assets/images/formacion3.jpg',
];

const texts = [
  { line1: 'Formación Especializada', line2: 'Para empresas y autónomos' },
  { line1: 'Modalidad Flexible', line2: 'Presencial u online' },
  { line1: 'Certificación y Seguimiento', line2: 'Asegura el éxito de tu equipo' },
];

export default function Formacion() {
  return (
    <Container>
      {/* Slideshow en la parte superior */}
      <Slideshow images={images} texts={texts} />

      {/* Título de la Página */}
      <Section>
        <Title variant="h4">Formación en IA: Capacita a tu equipo para el futuro</Title>
        <Typography variant="body1" paragraph>
          En EfficientAI, ofrecemos formación especializada en inteligencia artificial diseñada para empresas, autónomos y sus empleados.
          Ya sea en modalidad presencial o online, nuestras formaciones se adaptan a las necesidades y objetivos de cada negocio,
          asegurando que tu equipo obtenga las habilidades necesarias para implementar IA en sus procesos diarios.
        </Typography>
      </Section>

      {/* Sección: ¿Cómo funcionan nuestras formaciones? */}
      <Section>
        <SubTitle variant="h5">¿Cómo funcionan nuestras formaciones?</SubTitle>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <CardItem>
              <IconContainer>
                <PersonPinIcon />
              </IconContainer>
              <Typography variant="h6">Evaluación Personalizada</Typography>
              <Typography variant="body2">
                Analizamos las necesidades específicas de tu empresa para diseñar un programa de formación a medida, ajustado al nivel
                de conocimiento y los objetivos de tu equipo.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardItem>
              <IconContainer>
                <OnlinePredictionIcon />
              </IconContainer>
              <Typography variant="h6">Formación Presencial u Online</Typography>
              <Typography variant="body2">
                Ofrecemos la flexibilidad de elegir entre formaciones presenciales o online, adaptándonos al ritmo y disponibilidad
                de tus empleados. Siempre con un enfoque práctico y directo.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardItem>
              <IconContainer>
                <CheckCircleOutlineIcon />
              </IconContainer>
              <Typography variant="h6">Seguimiento y Actualización</Typography>
              <Typography variant="body2">
                Tras la formación, realizamos un seguimiento para asegurar que las herramientas y conocimientos adquiridos se estén
                aplicando correctamente y brindamos actualizaciones cuando sea necesario.
              </Typography>
            </CardItem>
          </Grid>
        </Grid>
      </Section>

      {/* Sección: Ventajas de nuestras formaciones */}
      <Section>
        <SubTitle variant="h5">Ventajas de nuestras formaciones</SubTitle>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={3}>
            <CardItem>
              <IconContainer>
                <SchoolIcon />
              </IconContainer>
              <Typography variant="h6">Formaciones Personalizadas</Typography>
              <Typography variant="body2">
                Diseñadas específicamente para los objetivos y necesidades de tu empresa.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem>
              <IconContainer>
                <BuildCircleIcon />
              </IconContainer>
              <Typography variant="h6">Capacitación Práctica</Typography>
              <Typography variant="body2">
                Aprende a utilizar herramientas de IA que pueden implementarse directamente en tus procesos de negocio.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem>
              <IconContainer>
                <AccessTimeIcon />
              </IconContainer>
              <Typography variant="h6">Flexibilidad</Typography>
              <Typography variant="body2">
                Opciones de formación presencial u online, adaptadas a la disponibilidad de tu equipo.
              </Typography>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem>
              <IconContainer>
                <VerifiedUserIcon />
              </IconContainer>
              <Typography variant="h6">Certificación y Seguimiento</Typography>
              <Typography variant="body2">
                Certificamos a tu equipo tras la formación y brindamos seguimiento para garantizar su éxito.
              </Typography>
            </CardItem>
          </Grid>
        </Grid>
      </Section>

      {/* Sección: Llamado a la acción */}
      <Section>
        <Typography variant="h5" gutterBottom>¿Listo para capacitar a tu equipo en IA?</Typography>
        <Typography variant="body1" paragraph>
          Contáctanos y descubre cómo nuestras formaciones personalizadas pueden preparar a tu equipo para aprovechar al máximo las herramientas de IA y mejorar la productividad.
        </Typography>
        <Button variant="contained" color="primary" startIcon={<MailOutlineIcon />}>
          Contáctanos
        </Button>
      </Section>

      {/* Sección: Suscripción al Newsletter */}
      <Section>
        <SubTitle variant="h5">Suscríbete a nuestro Newsletter</SubTitle>
        <Typography variant="body1">
          Mantente al día con las últimas tendencias y novedades en inteligencia artificial y automatización.
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <Box component="form" display="flex" alignItems="center">
            <input
              type="email"
              placeholder="Tu correo electrónico"
              style={{
                padding: '10px',
                borderRadius: '4px 0 0 4px',
                border: '1px solid #ccc',
                outline: 'none',
              }}
            />
            <Button variant="contained" color="secondary" style={{ borderRadius: '0 4px 4px 0' }}>
              Suscribirse
            </Button>
          </Box>
        </Box>
      </Section>
    </Container>
  );
}
