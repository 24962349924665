import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../theme';

const BannerContainer = styled(Box)({
  background: colors.gradient,
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  width: '100%',
  fontSize: '1.25rem',
  fontWeight: 500,
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
  margin: '2rem 0',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
});

export default function FullWidthBanner({ text }) {
  return (
    <BannerContainer>
      <Typography variant="body1">{text}</Typography>
    </BannerContainer>
  );
}
