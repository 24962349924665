import React from 'react';
import BubbleGallery from '../components/BubbleGallery';

const images = [
  { src: '/assets/images/gallery1.jpg', caption: 'Imagen 1' },
  { src: '/assets/images/gallery2.jpg', caption: 'Imagen 2' },
  { src: '/assets/images/gallery3.jpg', caption: 'Imagen 3' },
  { src: '/assets/images/gallery4.jpg', caption: 'Imagen 4' },
  { src: '/assets/images/gallery5.jpg', caption: 'Imagen 5' },
  { src: '/assets/images/gallery6.jpg', caption: 'Imagen 6' },
  { src: '/assets/images/gallery7.jpg', caption: 'Imagen 7' },
  { src: '/assets/images/gallery8.jpg', caption: 'Imagen 8' },
  { src: '/assets/images/gallery9.jpg', caption: 'Imagen 9' },
  { src: '/assets/images/gallery10.jpg', caption: 'Imagen 10' },
];

export default function GalleryPage() {
  return (
    <div>
      <h1>Galería Dinámica de Burbujas</h1>
      <BubbleGallery images={images} />
    </div>
  );
}
